import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import BlogPage from './components/BlogPage.jsx';
import  Home  from './component/Home';
import FloatingButtons from './component/FloatingButtons.jsx';
function App() {

  return (
    <>
      <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services/:serviceId" element={<BlogPage />} />
      </Routes>
    </Router>
    <FloatingButtons/>
</>

  );
}

export default App;
