import React from 'react';
import { Typography, Box } from '@mui/material';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer" style={{ padding: '20px', backgroundColor: '#333', color: '#fff', textAlign: 'center' }}>
      <Typography variant="body1" style={{ marginBottom: '10px' }}>
        &copy; 2024 odiweb.in
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: '15px' }}>
        <a href="https://www.facebook.com/profile.php?id=61563749431857&mibextid=rS40aB7S9Ucbxw6v" target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>
          <FaFacebookF size={24} />
        </a>
        <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>
          <FaTwitter size={24} />
        </a>
        <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>
          <FaLinkedinIn size={24} />
        </a>
        <a href="https://www.instagram.com/odiweb.in/" target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>
          <FaInstagram size={24} />
        </a>
      </Box>
    </footer>
  );
};

export default Footer;
