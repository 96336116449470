import React, { useState } from "react";
import "./header.css";
import Logo from '../portfolioIMG/odiweb.png'

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  return (
    <>
      <header className="navbar">
        <div className="navbar-container">
          <img
            src={Logo}
            alt="OdiWeb Solutions Logo"
            className="navbar-logo"
            href="/"
          />
          

          <nav className="navbar-links">
            <a href="/" className="nav-link">
              Home
            </a>
            <a href="/#features" className="nav-link">
            Services
            </a>
            <a href="/#portfolio" className="nav-link">
              Portfolio
            </a>
            <a href="/#testimonials" className="nav-link">
              Testimonials
            </a>
          </nav>

          <a href="tel:+918917412728" className="cta-button">
            Connect Now
          </a>

          <button className="menu-icon" onClick={toggleDrawer(true)}>
            &#9776;
          </button>
        </div>
      </header>

      <aside className="drawer" style={ drawerOpen ? {right: "-50px"} : {right: "-340px"}}>
        <button className="close-drawer" onClick={toggleDrawer(false)}>
          &times;
        </button>
        <nav className="drawer-links">
          <a href="/" className="drawer-link" onClick={toggleDrawer(false)}>
            Home
          </a>
          <a href="/#features" className="drawer-link" onClick={toggleDrawer(false)}>
          Services
          </a>
          
          <a href="/#portfolio" className="drawer-link"onClick={toggleDrawer(false)}>
            Portfolio
          </a>
          <a href="/#testimonials" className="drawer-link" onClick={toggleDrawer(false)}>
            Testimonials
          </a>
        </nav>
      </aside>
    </>
  );
};

export default Header;
