import React from 'react';
import Carousel from './carousel';
import Header from './navbar';
import Services from './services';
import Testimonial from './Testimonial';
import Portfolio from './portfolio';
import Contact from './contact';
import Footer from './footer';

function Home() {
  const slides = [
    <div className="carousel__slide-content">
      <h2>One stop solution for your business.</h2>
      <p>
        Welcome to odiweb Solutions      </p>
      <button className="carousel__btn" >
         <a href="https://api.whatsapp.com/send?phone=918917412728&text=Hlw%20OdiWeb%20I%20am%20interested%20in%20your%20services.">Get Started</a></button>
    </div>,
    <div className="carousel__slide-content">
      <h2>Creative Solutions</h2>
      <p>
        We provide innovative and creative solutions tailored to your needs.
      </p>
      <button className="carousel__btn" ><a href="/#features">Our Services</a></button>
      <button className="carousel__btn">Get Started</button>
    </div>,
    <div className="carousel__slide-content">
      <h2>Experienced & Talented Team</h2>
      <p>
        Our team of experienced and talented professionals are dedicated to delivering the best results.
      </p>
      <button className="carousel__btn">Learn More</button>
      <button className="carousel__btn" > <a href="/#contact">Contact Us</a></button>
    </div>,
    
    <div className="carousel__slide-content">
      <h2>Quality Assurance</h2>
      <p>
        We ensure top-notch quality in every project we undertake.
      </p>
      <button className="carousel__btn"><a href='/#portfolio'>See Portfolio</a></button>
      <button className="carousel__btn">Our Process</button>
    </div>,
    <div className="carousel__slide-content">
      <h2>Client Satisfaction</h2>
      <p>
        Client satisfaction is our top priority.
      </p>
      <button className="carousel__btn">Testimonials</button>
      <button className="carousel__btn">Join Us</button>
    </div>,
    <div className="carousel__slide-content">
      <h2>Global Reach</h2>
      <p>
        We have a global presence and work with clients worldwide.
      </p>
      <button className="carousel__btn">Learn More</button>
      <button className="carousel__btn"><a href="https://api.whatsapp.com/send?phone=918917412728&text=Hlw%20OdiWeb%20I%20am%20interested%20in%20your%20services.">Contact Us</a>      </button>
    </div>,
  ];

  return (
    <><Header />
      <Carousel className="homebanner" slides={slides} />
      <Services />
      <Testimonial />
      <Portfolio />
      <Contact />
      <Footer />
    </>
  );
}

export default Home;
