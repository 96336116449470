import React, { useState, useRef, useEffect } from 'react';
import './Carousel.css';

const Carousel = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false); // State to track hover status
  const slideRef = useRef(null);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    // Only set interval if not hovered
    if (!isHovered) {
      const slideInterval = setInterval(() => {
        nextSlide();
      }, 3000);

      return () => {
        clearInterval(slideInterval);
      };
    }
  }, [isHovered, currentIndex]); // Depend on isHovered

  useEffect(() => {
    if (slideRef.current) {
      slideRef.current.style.transition = 'transform 0.5s ease-in-out';
      slideRef.current.style.transform = `translateX(calc(-100% * ${currentIndex}))`;
    }
  }, [currentIndex]);

  return (
    <div
      className="carousel homebanner"
      onMouseEnter={() => setIsHovered(true)} // Set hover state to true on mouse enter
      onMouseLeave={() => setIsHovered(false)} // Set hover state to false on mouse leave
    >
      <button className="carousel__button carousel__button--left" onClick={prevSlide}>
        &#10094;
      </button>

      <div className="carousel__content" ref={slideRef}>
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`carousel__slide ${
              index === currentIndex ? 'carousel__slide--active' : ''
            }`}
          >
            {slide}
          </div>
        ))}
      </div>

      <button className="carousel__button carousel__button--right" onClick={nextSlide}>
        &#10095;
      </button>

      <div className="carousel__indicators">
        {slides.map((_, index) => (
          <span
            key={index}
            className={`carousel__indicator ${
              index === currentIndex ? 'carousel__indicator--active' : ''
            }`}
            onClick={() => setCurrentIndex(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
