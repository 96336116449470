import React from 'react';
import './Services.css';
import { FaIndustry, FaBullhorn, FaShoppingCart, FaCode, FaCog, FaChartLine, FaAddressBook, FaBlog, FaFacebookF } from 'react-icons/fa';
import {Link} from 'react-router-dom';
import { AiOutlineStock } from "react-icons/ai";

const servicesData = [
  {
    name: 'ERP Solutions',
    description: 'Comprehensive ERP solutions to streamline your business processes.',
    icon: <FaIndustry />,
    link:'erp'
  },
  {
    name: 'Sales Campaigns',
    description: 'Strategic sales campaigns to boost your revenue and reach your target audience.',
    icon: <FaBullhorn />,
    link:'sales'
  },
  {
    name: 'Ecommerce Support',
    description: 'End-to-end ecommerce support including setup, optimization, and management.',
    icon: <FaShoppingCart />,
    link:'ecommerce'
  },
  {
    name: 'Web Services',
    description: 'Custom web services to enhance your online presence and functionality.',
    icon: <FaCode />,
    link:'web'
  },
  {
    name: 'Operation Management Planning',
    description: 'Efficient planning for operational management to optimize resources and processes.',
    icon: <FaCog />,
    link:'operation'
  },
  {
    name: 'Marketing Strategy',
    description: 'Data-driven marketing strategies to drive growth and improve your market position.',
    icon: <FaChartLine />,
    link:'marketing'
  },
  {
    name: 'Franchise Model Planning',
    description: 'Comprehensive planning for franchise models to scale your business effectively.',
    icon: <FaBlog />,
    link:'franchise'
  },
  {
    name: 'CRM Support',
    description: 'Robust CRM support to manage customer relationships and drive sales.',
    icon: <FaAddressBook />,
    link:'crm'
  },
  {
    name: 'CMS Development',
    description: 'Custom CMS development to manage your website content efficiently.',
    icon: <FaBlog />,
    link:'cms'
  },
  {
    name: 'Social Media Management',
    description: 'Expert social media management to boost your online presence and engagement.',
    icon: <FaFacebookF />,
    link:'social'
  },{
    name: 'IPO Support',
    description: 'Professional guidance and support for Initial Public Offering (IPO)',
    icon: <AiOutlineStock />,
    link:'ipo'
  }

];

const Services = () => {
  return (
    <div className="services-container" id="features">
    <h2 className="section-title">Our Services</h2>
    <div className="services-grid">
      {servicesData.map((service) => (
        <div className="service-card" key={service.name}>
          <Link to={`/services/${service.link}`} className="service-link">
            <div className="service-icon">
              {service.icon}
            </div>
            <h3 className="service-title">{service.name}</h3>
            <p className="service-description">{service.description}</p>
          </Link>
        </div>
      ))}
    </div>
  </div>
  );
};

export default Services;
