import React from "react";
import { useParams } from "react-router-dom";
import "./BlogPage.css";
import Header from "../component/navbar";
import Contact from "../component/contact";
import Footer from "../component/footer";
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import ERPIMG from "../portfolioIMG/erp-image.png";
import Sales from "../portfolioIMG/sales-image.png";
import { Helmet } from 'react-helmet';

const blogContent = {
  erp: {
    title: "ERP Solutions: Streamlining Your Business Operations",
    subtitle: "Achieve operational excellence with our tailored ERP solutions.",
    image: ERPIMG, // Replace with actual image path
    intro: `
      In today's fast-paced business environment, staying ahead requires more than just hard work—it requires smart work. Our ERP Solutions are designed to help you achieve operational excellence by automating routine tasks, integrating disparate systems, and providing real-time insights into your business processes. By leveraging our expertise, you can free up valuable resources to focus on high-value activities that drive growth and innovation. With our ERP solutions, you can streamline your operations, reduce costs, and improve overall efficiency, giving you a competitive edge in the market. Whether you're a small startup or a large enterprise, our ERP solutions are tailored to meet your specific needs and help you achieve your business goals.
    `,
    benefits: [
      {
        title: "Efficiency",
        description: `
          Automate repetitive and time-consuming tasks, such as data entry, inventory management, and accounting, to free up valuable resources and reduce labor costs. Our ERP solutions are designed to streamline your operations, making it easier to manage your business and stay ahead of the competition. By improving efficiency, you can focus on strategic initiatives that drive growth.
        `,
      },
      {
        title: "Integration",
        description: `
          Seamlessly connect all departments within your organization, including sales, marketing, finance, and operations, to ensure that everyone is on the same page. Our ERP solutions provide a single, unified platform for all your business needs, making it easier to manage your operations and make informed decisions. This integration reduces data silos and enhances collaboration across teams.
        `,
      },
      {
        title: "Scalability",
        description: `
          Grow your business without worrying about outgrowing your system. Our ERP solutions are designed to scale with your business, providing a flexible and adaptable platform that can meet your changing needs. Whether you're expanding into new markets or increasing your product offerings, our ERP solutions are designed to help you achieve your goals without the need for frequent upgrades.
        `,
      },
    ],
    useCase: `
      Imagine a manufacturing company struggling with manual inventory tracking, leading to frequent stockouts and overstock situations. Our ERP system streamlines inventory management, providing real-time data and automated restocking processes, ensuring optimal stock levels at all times. By leveraging our ERP solutions, the company can reduce inventory costs, improve customer satisfaction, and increase revenue. Additionally, the integrated reporting tools allow management to make data-driven decisions, further enhancing operational efficiency.
    `,
    conclusion: `
      Our ERP Solutions are tailored to meet the specific needs of your industry, whether you're in manufacturing, retail, or services. We work closely with you to ensure a smooth implementation and provide ongoing support to keep your business running at its best. With our ERP solutions, you can achieve operational excellence, reduce costs, and improve overall efficiency, giving you a competitive edge in the market. Contact us today to learn more about how our ERP solutions can help you achieve your business goals and drive sustainable growth.
    `,
  },
  sales: {
    title: "Sales Campaigns: Driving Growth with Strategic Marketing",
    subtitle:
      "Unlock your business potential with expertly crafted sales campaigns.",
    image: Sales, // Replace with actual image path
    intro: `
      Effective sales campaigns are the backbone of any successful business. Our approach combines data-driven strategies with creative execution to help you reach your target audience and drive revenue growth. We analyze market trends, customer behavior, and competitive positioning to design campaigns that resonate with your audience and deliver measurable results.
    `,
    benefits: [
      {
        title: "Targeted Reach",
        description: `
          Identify and connect with your ideal customers through precision targeting. By leveraging market research and customer data, we ensure that your campaigns reach the right people, at the right time, with the right message. This targeted approach maximizes your marketing ROI and drives higher conversion rates.
        `,
      },
      {
        title: "Creative Strategies",
        description: `
          Stand out in a crowded market with innovative and engaging campaigns. Our team combines creativity with strategic thinking to develop unique concepts that capture attention and drive action. From eye-catching visuals to compelling copy, we craft campaigns that leave a lasting impression on your audience.
        `,
      },
      {
        title: "Measurable Results",
        description: `
          Track the success of your campaigns with detailed analytics and reporting. We provide insights into key performance metrics, such as engagement rates, lead generation, and sales conversion, allowing you to assess the effectiveness of your campaigns and make data-driven adjustments for continuous improvement.
        `,
      },
    ],
    useCase: `
      Consider a retail brand launching a new product. Our team designs a multi-channel sales campaign that includes social media, email marketing, and influencer partnerships. This integrated approach generates buzz, drives traffic to the brand's website, and results in a significant increase in product sales from day one. By analyzing campaign performance, we optimize strategies to ensure ongoing success and engagement.
    `,
    conclusion: `
      We don't just create campaigns—we create experiences that resonate with your audience. Our team of experts ensures that every campaign is aligned with your brand's voice and goals, delivering results that exceed expectations. Partner with us to unlock your business potential and achieve sustained growth through strategic marketing.
    `,
  },
  ecommerce: {
    title: "Ecommerce Support: Enhancing Your Online Store",
    subtitle:
      "Optimize your ecommerce platform for maximum performance and growth.",
    // // // image: "ecommerce-image.jpg", // Replace with actual image path
    intro: `
      The ecommerce landscape is more competitive than ever. To succeed, you need more than just a great product—you need a seamless online shopping experience. Our Ecommerce Support services cover everything from setup to optimization and ongoing management. We help you create an engaging, user-friendly online store that attracts and retains customers.
    `,
    benefits: [
      {
        title: "Comprehensive Setup",
        description: `
          We handle everything from product listings to payment gateways, ensuring a smooth setup process for your online store. Our team ensures that all technical aspects are addressed, including inventory management, shipping options, and customer service integration, so you can focus on growing your business.
        `,
      },
      {
        title: "Optimization",
        description: `
          Improve site speed, user experience, and conversion rates through targeted optimization efforts. We analyze your ecommerce platform to identify areas for improvement, such as reducing load times, enhancing navigation, and streamlining the checkout process. These enhancements lead to increased customer satisfaction and higher sales.
        `,
      },
      {
        title: "Management",
        description: `
          Ongoing support to keep your store running smoothly and adapt to changing market conditions. We provide regular maintenance, security updates, and performance monitoring to ensure that your ecommerce platform remains reliable and effective in driving sales.
        `,
      },
    ],
    useCase: `
      A fashion retailer struggling with slow website performance and low conversion rates sees a 30% increase in sales after we optimize their ecommerce platform. By enhancing their product pages, improving site speed, and streamlining the checkout process, we deliver a better shopping experience that drives revenue growth.
    `,
    conclusion: `
      We understand that every ecommerce business is unique. That's why we tailor our services to meet your specific needs, ensuring that your online store not only looks great but also performs at its best. With our expertise, you can stay ahead of the competition and achieve long-term success in the dynamic ecommerce landscape.
    `,
  },
  web: {
    title: "Web Services: Crafting Your Digital Presence",
    subtitle:
      "Build a captivating online presence with our web design and development services.",
    // // // image: "web-image.jpg", // Replace with actual image path
    intro: `
      In the digital age, your website is often the first interaction customers have with your brand. Our Web Services ensure that this first impression is not only positive but also lasting. We offer custom web design, development, and maintenance services that reflect your brand's identity and values. Our goal is to create a website that engages visitors and drives conversions.
    `,
    benefits: [
      {
        title: "Custom Design",
        description: `
          Unique, responsive designs tailored to your brand. We create visually stunning websites that capture your brand's essence and provide an exceptional user experience across all devices. Our custom designs ensure that your website stands out and resonates with your target audience.
        `,
      },
      {
        title: "Full-Stack Development",
        description: `
          From front-end design to back-end integration, our full-stack development services cover all aspects of website creation. We build robust, scalable solutions that meet your functional requirements and provide a seamless user experience. Our expertise ensures that your website performs optimally and supports your business goals.
        `,
      },
      {
        title: "Ongoing Maintenance",
        description: `
          Keeping your site secure, fast, and up-to-date with regular maintenance. We offer comprehensive support to address any issues, implement updates, and enhance performance. Our maintenance services ensure that your website remains effective and reliable over time.
        `,
      },
    ],
    useCase: `
      A startup company launches their new website, designed and developed by our team. The result is a sleek, user-friendly interface that attracts investors and customers alike, driving both engagement and conversions. By aligning the website with the company's vision and objectives, we deliver a powerful digital presence.
    `,
    conclusion: `
      We don't just build websites; we build digital experiences. Our team works closely with you to understand your vision and bring it to life, ensuring your website is not just beautiful but also functional and effective. Partner with us to craft a compelling online presence that drives business growth.
    `,
  },
  operation: {
    title: "Operation Management Planning: Optimizing Your Resources",
    subtitle:
      "Enhance efficiency and productivity with strategic operations management.",
    // // // image: "operations-image.jpg", // Replace with actual image path
    intro: `
      Efficient operations are the backbone of any successful business. Our Operation Management Planning services are designed to help you optimize resources, reduce waste, and improve productivity across your organization. We analyze your existing processes and develop strategies to enhance efficiency and effectiveness.
    `,
    benefits: [
      {
        title: "Resource Optimization",
        description: `
          Make the most of your time, money, and materials by implementing resource optimization strategies. We identify areas where resources can be better utilized and develop solutions to reduce waste and increase efficiency. This approach helps you achieve more with less, driving overall cost savings.
        `,
      },
      {
        title: "Process Improvement",
        description: `
          Streamline workflows to reduce bottlenecks and enhance productivity. Our process improvement strategies focus on eliminating inefficiencies and optimizing operations, resulting in faster turnaround times and improved output quality. By refining your processes, we help you achieve operational excellence.
        `,
      },
      {
        title: "Cost Reduction",
        description: `
          Identify and eliminate inefficiencies to reduce operational costs. Our cost reduction strategies involve analyzing expenses, identifying cost drivers, and implementing measures to control and minimize costs. This approach helps you improve your bottom line and achieve greater profitability.
        `,
      },
    ],
    useCase: `
      A logistics company struggling with delayed deliveries and high operational costs sees a 20% improvement in delivery times and a 15% reduction in costs after we implement a new operation management plan. Our strategies streamline their processes, enhance resource utilization, and improve overall performance.
    `,
    conclusion: `
      Our approach to operation management is both strategic and practical. We work with you to identify areas of improvement and implement solutions that are sustainable and scalable, ensuring your operations run smoothly now and in the future. With our expertise, you can achieve greater efficiency and drive business success.
    `,
  },
  marketing: {
    title: "Marketing Strategy: Data-Driven Growth",
    subtitle: "Harness the power of data to drive your marketing success.",
    // // // image: "marketing-image.jpg", // Replace with actual image path
    intro: `
      In a world of constant change, a well-crafted marketing strategy is your key to staying ahead of the competition. Our Marketing Strategy services focus on leveraging data to make informed decisions that drive growth and enhance your market position. We combine analytical insights with creative strategies to deliver impactful results.
    `,
    benefits: [
      {
        title: "Data-Driven Insights",
        description: `
          Make decisions based on real data, not guesswork. Our data-driven approach provides actionable insights into market trends, customer behavior, and campaign performance. By utilizing these insights, we help you make informed decisions that drive growth and improve marketing effectiveness.
        `,
      },
      {
        title: "Multi-Channel Approach",
        description: `
          Reach your audience across multiple platforms with a cohesive strategy. We design marketing campaigns that integrate various channels, such as social media, email, and content marketing, to maximize your reach and engagement. This multi-channel approach ensures that your message reaches your target audience wherever they are.
        `,
      },
      {
        title: "Continuous Optimization",
        description: `
          Regularly refine your strategy for maximum impact. We monitor campaign performance and make data-driven adjustments to optimize results. This continuous optimization process ensures that your marketing efforts remain effective and aligned with your business goals.
        `,
      },
    ],
    useCase: `
      A tech company looking to expand its market share leverages our data-driven marketing strategies, resulting in a 40% increase in lead generation and a 25% boost in sales within six months. By analyzing market data and refining their approach, we deliver substantial growth and improved marketing ROI.
    `,
    conclusion: `
      We believe that marketing is both an art and a science. Our strategies are creative yet grounded in data, ensuring that every campaign we run is both innovative and effective. Partner with us to harness the power of data and drive your marketing success.
    `,
  },
  franchise: {
    title: "Franchise Model Planning: Scaling Your Business",
    subtitle:
      "Expand your business sustainably with a well-structured franchise model.",
    // // // image: "franchise-image.jpg", // Replace with actual image path
    intro: `
      Expanding your business through franchising is a powerful way to scale, but it requires careful planning and execution. Our Franchise Model Planning services provide the blueprint you need to grow your business sustainably and successfully. We help you develop a robust franchise model that aligns with your business goals and ensures consistent quality across locations.
    `,
    benefits: [
      {
        title: "Strategic Planning",
        description: `
          Develop a franchise model that aligns with your business goals. We work with you to create a comprehensive plan that addresses key aspects of franchising, including market research, franchisee recruitment, and operational standards. Our strategic planning ensures that your franchise model supports long-term growth and success.
        `,
      },
      {
        title: "Legal Compliance",
        description: `
          Ensure your franchise agreements are legally sound and compliant with regulations. Our team helps you navigate the legal complexities of franchising, including contract drafting, intellectual property protection, and regulatory compliance. This ensures that your franchise operations are legally secure and aligned with industry standards.
        `,
      },
      {
        title: "Training & Support",
        description: `
          Provide your franchisees with the tools and resources they need to succeed. We develop comprehensive training programs and support systems to ensure that your franchisees are well-prepared to operate successfully. Ongoing support helps maintain brand consistency and quality across all franchise locations.
        `,
      },
    ],
    useCase: `
      A local restaurant chain expands to multiple locations by adopting our franchise model. With our guidance, they maintain brand consistency and quality across all franchises, leading to rapid and sustainable growth. The structured approach ensures that each franchise location operates efficiently and upholds the brand's reputation.
    `,
    conclusion: `
      We understand that franchising is more than just a growth strategy—it's about replicating success. Our expertise ensures that your franchise model is robust, scalable, and positioned for long-term success. Partner with us to develop a franchise model that supports your business goals and drives sustainable growth.
    `,
  },
  crm: {
    title: "CRM Support: Building Strong Customer Relationships",
    subtitle: "Enhance customer engagement and loyalty with our CRM solutions.",
    // // // image: "crm-image.jpg", // Replace with actual image path
    intro: `
      Customer relationships are the heart of any business. Our CRM Support services are designed to help you build, maintain, and enhance these relationships, driving customer loyalty and increasing sales. We provide solutions that enable you to understand your customers better, streamline interactions, and integrate CRM with other systems for a unified view of customer data.
    `,
    benefits: [
      {
        title: "Customer Insights",
        description: `
          Gain a deep understanding of your customers' needs and preferences. Our CRM solutions provide valuable insights into customer behavior, purchase history, and engagement patterns. By leveraging this data, you can tailor your marketing efforts and improve customer satisfaction.
        `,
      },
      {
        title: "Automation",
        description: `
          Streamline customer interactions with automated workflows. Our CRM solutions automate repetitive tasks, such as follow-ups, lead nurturing, and customer service requests, allowing your team to focus on high-value activities. Automation improves efficiency and ensures timely, personalized interactions with your customers.
        `,
      },
      {
        title: "Integration",
        description: `
          Connect your CRM with other systems for a unified view of customer data. Our solutions integrate with various business applications, such as marketing platforms, sales tools, and customer support systems. This integration provides a comprehensive view of customer interactions and enhances your ability to make informed decisions.
        `,
      },
    ],
    useCase: `
      A real estate firm struggling with lead management and customer follow-ups implements our CRM solutions. As a result, they experience a 50% increase in customer retention and a significant boost in sales. The CRM system streamlines lead tracking, automates follow-ups, and provides actionable insights into customer interactions.
    `,
    conclusion: `
      Our CRM solutions are not just about managing data—they're about creating meaningful connections. We help you leverage technology to enhance every stage of the customer journey, from acquisition to loyalty. With our CRM support, you can build stronger customer relationships and drive business growth.
    `,
  },
  cms: {
    title: "CMS Development: Control Your Content",
    subtitle:
      "Effortlessly manage your website content with our custom CMS solutions.",
    // // // image: "cms-image.jpg", // Replace with actual image path
    intro: `
      Managing content effectively is crucial for maintaining a dynamic and engaging website. Our CMS Development services provide you with a user-friendly platform to easily create, edit, and manage your website content without needing technical expertise. We build custom CMS solutions that are tailored to your needs and designed to simplify content management.
    `,
    benefits: [
      {
        title: "Ease of Use",
        description: `
          Intuitive interfaces that require no coding knowledge. Our CMS solutions are designed with user-friendliness in mind, allowing you to manage your content effortlessly. With easy-to-use tools and a straightforward interface, you can update your website quickly and efficiently.
        `,
      },
      {
        title: "Flexibility",
        description: `
          Customize your CMS to fit your specific needs. We build flexible CMS platforms that can be tailored to your content management requirements. Whether you need a simple blog or a complex content repository, our solutions provide the customization options you need.
        `,
      },
      {
        title: "SEO Friendly",
        description: `
          Optimize your content for search engines effortlessly. Our CMS solutions include built-in SEO features that help you improve your website's visibility in search engine results. With tools for optimizing meta tags, headings, and content, you can enhance your site's search engine performance.
        `,
      },
    ],
    useCase: `
      A non-profit organization struggling to keep their website up-to-date and engaging switches to our custom CMS. The result? An easy-to-manage website that allows them to regularly update content, improving their online presence and engagement. The CMS provides a user-friendly interface and essential features to keep the site dynamic and current.
    `,
    conclusion: `
      We build CMS solutions that empower you to take control of your content. Whether you need a simple blog platform or a complex content management system, we create solutions that are powerful, flexible, and easy to use. With our custom CMS, you can manage your content with ease and keep your website engaging and relevant.
    `,
  },
  social: {
    title: "Social Media Management: Amplify Your Online Presence",
    subtitle:
      "Build your brand and engage your audience with strategic social media management.",
    // // // image: "social-image.jpg", // Replace with actual image path
    intro: `
      In today's digital age, social media is more than just a marketing tool—it's a powerful platform for building your brand and connecting with your audience. Our Social Media Management services are designed to help you navigate the ever-changing social media landscape, ensuring your brand stays relevant and engaging.
    `,
    benefits: [
      {
        title: "Brand Consistency",
        description: `
          Maintain a cohesive brand voice across all platforms. We ensure that your brand message is consistent and recognizable, regardless of the social media platform. This consistency helps build brand trust and recognition among your audience.
        `,
      },
      {
        title: "Engagement",
        description: `
          Increase interaction with your audience through creative content and timely responses. Our social media strategies focus on fostering engagement and building a community around your brand. By creating compelling content and actively managing interactions, we help you connect with your audience on a deeper level.
        `,
      },
      {
        title: "Analytics",
        description: `
          Track performance and optimize strategies based on data. We use analytics to monitor social media performance, measure engagement, and assess the effectiveness of your campaigns. This data-driven approach allows us to refine strategies and maximize your social media impact.
        `,
      },
    ],
    useCase: `
      A growing fashion brand sees a significant increase in followers and engagement after implementing our social media strategies. Our approach results in a 20% boost in online sales and enhanced brand presence across social media platforms. By leveraging data-driven insights and creative content, we help the brand connect with its audience and drive growth.
    `,
    conclusion: `
      We understand that social media is about more than just posting content—it's about creating a dialogue with your audience. Our team develops and executes strategies that not only increase your online presence but also foster a community around your brand. Partner with us to amplify your social media impact and drive business success.
    `,
  },
  ipo: {
    title: "IPO with Merchant Banking Support: Plan Your Exit Strategy",
    subtitle:
      "Secure a successful initial public offering with expert guidance and support.",
    intro: `
      An IPO (Initial Public Offering) is a significant milestone for any business seeking to expand its reach and raise capital. Our IPOwith Merchant Banking Support services provide expert guidance and support throughout the entire process, ensuring a successful exit strategy for your business. We understand the complexities of IPOs and work closely with merchant banks to ensure a smooth and successful process.
    `,
    benefits: [
      {
        title: "IPO Structuring",
        description: `
          We work closely with your team to structure your IPO, ensuring that it aligns with your business goals and objectives. Our expertise in IPO structuring helps you maximize the value of your exit and position your business for long-term success.
        `,
      },
      {
        title: "Regulatory Compliance",
        description: `
          We understand the regulatory requirements surrounding IPOs and work closely with you to ensure compliance with all relevant laws and regulations. Our expert team is knowledgeable about the nuances of IPO regulations and provides guidance on how to navigate the process smoothly.
        `,
      },
      {
        title: "Market Strategy",
        description: `
          We help you develop a comprehensive market strategy that aligns with your business goals. By understanding your target market and competitive landscape, we can help you position your business for success and ensure that your IPO aligns with the market's needs.
        `,
      },
      {
        title: "Merchant Banking Support",
        description: `
          Our partnership with a leading merchant bank provides expert guidance and support throughout the entire IPO process. Our relationship with the merchant bank allows us to leverage their resources and expertise to ensure a successful exit strategy for your business.
        `,
      },
    ],
    useCase: `
      A tech startup seeking to go public secures a successful IPO with our expert guidance and support. Our team works closely with the merchant bank to ensure a smooth and successful exit strategy, resulting in a successful IPO and a path to long-term success for the business.
    `,
    conclusion: `
      We understand the significance of an IPO and the importance of a successful exit strategy. Our IPOwith Merchant Banking Support services provide expert guidance and support throughout the entire process, ensuring a successful outcome for your business.
    `,
  },

};

const BlogPage = () => {
  const { serviceId } = useParams();
  const service = blogContent[serviceId];

  if (!service) {
    return <p>Service not found.</p>;
  }

  return (
    <>
     <Helmet>
<meta property="og:url" content="https://odiweb.in"/>
<meta property="og:type" content="website"/>
<meta property="og:title" content="Odiweb Solutions"/>
<meta property="og:description" content="A One stop Digitalization solution for businesses. Manage customers, products, orders and invoices with ease."/>

<meta property="og:image" content="https://ogcdn.net/c078a98b-9a33-4eaf-a5cf-e5ebf3ea450c/v1/odiweb.in/Odiweb%20Solutions/A%20One%20stop%20Digitalization%20solution%20for%20businesses.%20Manage%20customers%2C%20products%2C%20orders%20and%20invoices%20with%20ease./https%3A%2F%2Fopengraph.b-cdn.net%2Fproduction%2Fimages%2F1895b5d0-2373-46b7-b1e0-703ae2251bf0.png%3Ftoken%3DUd5DHdWXjgRoo0XT_OgR1Yf9tkIgNaxqiRFclJcItck%26height%3D500%26width%3D500%26expires%3D33259576379/og.png"/>

<meta name="twitter:card" content="summary_large_image"/>
<meta property="twitter:domain" content="odiweb.in"/>
<meta property="twitter:url" content="https://odiweb.in"/>
<meta name="twitter:title" content="Odiweb Solutions"/>
<meta name="twitter:description" content="A One stop Digitalization solution for businesses. Manage customers, products, orders and invoices with ease."/>

<meta name="twitter:image" content="https://ogcdn.net/c078a98b-9a33-4eaf-a5cf-e5ebf3ea450c/v1/odiweb.in/Odiweb%20Solutions/A%20One%20stop%20Digitalization%20solution%20for%20businesses.%20Manage%20customers%2C%20products%2C%20orders%20and%20invoices%20with%20ease./https%3A%2F%2Fopengraph.b-cdn.net%2Fproduction%2Fimages%2F1895b5d0-2373-46b7-b1e0-703ae2251bf0.png%3Ftoken%3DUd5DHdWXjgRoo0XT_OgR1Yf9tkIgNaxqiRFclJcItck%26height%3D500%26width%3D500%26expires%3D33259576379/og.png"/>

      <meta name="description" content={service.intro} />
                <title>{service.title}</title>
            </Helmet>
      <Header />
      <div className="blog-page">
        <h1 className="blog-title">{service.title}</h1>
        {service.subtitle && (
          <h3 className="blog-subtitle">{service.subtitle}</h3>
        )}
       
        <div className="blog-breadcrumb">
          <Link to="/">
            <span>Home</span>
          </Link>
          <span><FaAngleRight /></span>
          <span className="current-page">{serviceId}</span>
        </div>
        {}
        <section className="blog-intro">
          <p>{service.intro}</p>
        </section>
        {service.image ? (
           <div className="blog-image-container">
            <img
              src={service.image}
              alt={service.title}
              className="blog-image"
            />
            
          </div>
        ):null}

        <section className="blog-benefits">
          <h2>Key Benefits</h2>
          <ul>
            {service.benefits.map((benefit, index) => (
              <li key={index}>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </li>
            ))}
          </ul>
        </section>

        <section className="blog-usecase">
          <h2>Use Case</h2>
          <blockquote>{service.useCase}</blockquote>
        </section>

        <section className="blog-conclusion">
          <h2>Conclusion</h2>
          <p>{service.conclusion}</p>
        </section>
      </div>
      <Contact />
      <Footer />
    </>
  );
};

export default BlogPage;
