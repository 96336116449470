import React from 'react';
import './Testimonial.css'
// import { FaPlay } from 'react-icons/fa'; // Icon for video play button
import DP from '../portfolioIMG/dp.png'
const testimonials = [
  {
    name: 'Rajesh Kumar',
    location: 'Kolkata, West Bengal',
    profession: 'Jeweler',
    testimonial: 'Our ERP system has streamlined our inventory management and sales tracking, significantly increasing our operational efficiency. Highly recommend this service for jewelers!',
    image: DP, // Replace with real image URL
    video: 'https://via.placeholder.com/200', // Replace with real video URL
  },
  {
    name: 'Mrs.Anjali Reddy',
    location: 'Visakhapatnam, Andhra Pradesh',
    profession: 'D2C Farmer',
    testimonial: 'The e-commerce support provided has transformed our direct-to-consumer business. Our online sales have skyrocketed, and the CRM tools have improved customer relationships.',
    image: DP, // Replace with real image URL
    video: 'https://via.placeholder.com/200', // Replace with real video URL
  },
  {
    name: 'Mr. Sourav Ghosh',
    location: 'Bhubaneswar, Odisha',
    profession: 'Retailer',
    testimonial: 'The operation management optimization services have helped us reduce costs and enhance productivity. The ERP and CRM solutions are top-notch and tailored to our needs.',
    image: DP, // Replace with real image URL
    video: 'https://via.placeholder.com/200', // Replace with real video URL
  },
  {
    name: 'Mr. Birendra Gupta',
    location: 'Cuttack, Odisha',
    profession: 'Jeweler Manufacturer & Wholesaler',
    testimonial: "Odiweb's expertise in ERP tailored to my custom needs helped me achieve 135% growth in production. Their smooth management system has been a game changer for me.",
    image: DP, // Replace with real image URL
    video: 'https://via.placeholder.com/200', // Replace with real video URL
  },
  {
    name: 'Mr. Arjun Sharma',
    location: 'Delhi, India',
    profession: 'Tech Startup Founder',
    testimonial: 'The CRM and e-commerce support have significantly boosted our startup’s growth. The customized solutions offered were exactly what we needed for scaling up our operations.',
    image: DP, // Replace with real image URL
    video: 'https://via.placeholder.com/200', // Replace with real video URL
  },
  {
    name: 'Ms.Rita Roy',
    location: 'Ranchi, Jharkhand',
    profession: 'Educational Consultant',
    testimonial: 'The operation management services have optimized our workflow, and the CRM support has improved our client interactions. Fantastic results and professional service.',
    image: DP, // Replace with real image URL
    video: 'https://via.placeholder.com/200', // Replace with real video URL
  },
];

const Testimonial = () => {
    return (
      <div className="testimonial-container" id="testimonials">
        <h2 className="section-title">What Our Clients Say</h2>
        <div className="testimonial-grid">
          {testimonials.map((testimonial, index) => (
            <div className="testimonial-card" key={index}>
              <div className="testimonial-header">
                <img src={testimonial.image} alt={testimonial.name} className="testimonial-image" />
                <div className="testimonial-info">
                  <h3 className="testimonial-name">{testimonial.name}</h3>
                  <p className="testimonial-location">{testimonial.location}</p>
                  <p className="testimonial-profession">{testimonial.profession}</p>
                </div>
              </div>
              <p className="testimonial-text">
                "{testimonial.testimonial}"
              </p>
              {/* Uncomment to show video thumbnail */}
              {/* 
              <div className="video-thumbnail">
                <img src={testimonial.video} alt="Video thumbnail" className="thumbnail-image" />
                <div className="play-icon">
                  <FaPlay size={40} color="white" />
                </div>
              </div>
              */}
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default Testimonial;
