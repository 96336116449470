import React from 'react';
import './FloatingButtons.css'; // Import CSS file for styling
import { FaWhatsapp } from "react-icons/fa";
const FloatingButtons = () => {
  // Function to handle scroll to top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="floating-buttons">
      {/* Scroll to Top Button */}
      <button className="floating-button top-button" onClick={scrollToTop}>
        ↑
      </button>

      {/* WhatsApp Button */}
      <a
        href="https://api.whatsapp.com/send?phone=918917412728&text=Hlw%20OdiWeb%20I%20am%20interested%20in%20your%20services."
        target="_blank"
        rel="noopener noreferrer"
        className="floating-button whatsapp-button"
      >
        <FaWhatsapp />
      </a>
    </div>
  );
};

export default FloatingButtons;
