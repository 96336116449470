import React from 'react';
import './Portfolio.css'; 
import travelImage from '../portfolioIMG/travel.jpeg'; // Adjust path according to your project structure
import odiwebImage from '../portfolioIMG/jewelerp.png';
import shagoon from '../portfolioIMG/shagoon.png'
 // Adjust path according to your project structure

const projects = [
  {
    title: 'Travel Website',
    description: 'A comprehensive travel website offering booking services, travel guides, and user reviews. Features include real-time availability, custom itineraries, and integrated payment systems.',
    image: travelImage,
  },
  {
    title: 'ERP System for Jewelry',
    description: 'An ERP solution tailored for the jewelry industry, providing inventory management, sales tracking, and customer relationship management. Enhances operational efficiency and streamlines business processes.',
    image: odiwebImage,
  },
  {
  title: 'Ecommerce for Jewelry',
  description: 'An ecommerce solution designed specifically for the jewelry industry, featuring inventory management, sales tracking, and seamless customer interactions. Streamlines business operations and maximizes revenue potential.',
  image: shagoon,
},
  // Add more projects as needed
];

const Portfolio = () => {
    return (
      <div className="portfolio-container" id="portfolio">
        <h2 className="section-title">Our Work</h2>
        <div className="portfolio-grid">
          {projects.map((project, index) => (
            <div className="portfolio-item" key={index}>
              <img
                src={project.image}
                alt={project.title}
                className="portfolio-image"
              />
              <div className="portfolio-content">
                <h3 className="portfolio-title">{project.title}</h3>
                <p className="portfolio-description">
                  {project.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default Portfolio;
