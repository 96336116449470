import React from "react";
import "./contact.css";
import { InstagramEmbed, FacebookEmbed } from "react-social-media-embed";

export default function Contact() {
  const [result, setResult] = React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "52011f20-4862-4780-a4e2-73ecefa975d0");
    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  return (
    <div className="contact">
      <div className="social" style={{ padding: "10px" }}>
        <InstagramEmbed
          url="https://www.instagram.com/p/C-e0Ot1TUgK"
          
        />

        <FacebookEmbed
          url="https://www.facebook.com/permalink.php?story_fbid=pfbid0Kn1nSi2ZYmVzN7JRFn7ujv6wUdvshMFyD4GuiqJ2v6mRF221h1bTpDGZdfmTuiNMl&id=61563749431857&rdid=MZmn0ZSZzYl5PNBO"
          
        />
      </div>

      <div className="contact-form">
        <h2>Contact Us</h2>
        <form onSubmit={onSubmit}>
          <label htmlFor="name">Name</label>
          <input type="text" id="name" name="name" required />

          <label htmlFor="email">Email</label>
          <input type="email" id="email" name="email" required />

          <label htmlFor="business">Business Type</label>
          <select id="business" name="business">
            <option value="">Select Business Type</option>
            <option value="individual">Individual</option>
            <option value="small-business">Small Business</option>
            <option value="medium-business">Medium Business</option>
            <option value="large-business">Large Business</option>
          </select>

          <label htmlFor="turnover">Last Quarter Turnover (Rs.)</label>

          <label htmlFor="industry">Industry Type</label>
          <select id="industry" name="industry">
            <option value="">Select Industry Type</option>
            <option value="jewelry">Jewelry</option>
            <option value="fashion">Fashion</option>
            <option value="footwear">Footwear</option>
            <option value="cosmetics">Cosmetics</option>
            <option value="electronics">Electronics</option>
            <option value="industrial">Industrial</option>
            <option value="other">Other</option>
          </select>

          <label htmlFor="turnover">Last Quarter Turnover (Rs.)</label>

          <select id="turnover" name="turnover">
            <option value="">Select Turnover Range</option>
            <option value="less-than-1l">Less than 1 Lakh</option>
            <option value="5l-10l">5 Lakh to 10 Lakh Rs.</option>
            <option value="more-than-10l">More than 10 Lakh Rs.</option>
            <option value="10l-50l">10 Lakh to 50 Lakh</option>
            <option value="50l-1cr">50 Lakh to 1 crore</option>
            <option value="more-than-1cr">More than 1 crore</option>
          </select>

          <label htmlFor="company-name">Company Name</label>
          <input type="text" id="company-name" name="company-name" />

          <label htmlFor="company-email">Company Email</label>
          <input type="email" id="company-email" name="company-email" />

          <label htmlFor="message">Message</label>
          <textarea id="message" name="message" required></textarea>

          <button type="submit">Submit Form</button>
        </form>
        <span>{result}</span>
      </div>
    </div>
  );
}
